import { website } from '@getpopsure/private-constants';
import { SliceZone } from '@prismicio/react';
import { PrismicDocument } from '@prismicio/types';
import { captureException } from '@sentry/nextjs';
import MissingRequiredFields from 'components/MissingRequiredFields';
import {
  ENGLISH_LOCALE_IDENTIFIER,
  FORMATTED_DEFAULT_LOCALE,
  GERMAN_LOCALE_IDENTIFIER,
  PRISMIC_LOCALES,
} from 'constants/i18n';
import { DEFAULT_SUBDOMAIN_IDENTIFIER } from 'constants/subdomains';
import { getLatestPostsByCategory } from 'endpoint';
import { GetStaticPathsContext, GetStaticPropsContext } from 'next';
import Head from 'next/head';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextSeo } from 'next-seo';
import { createPrismicClient } from 'prismicio';
import React from 'react';
import { components } from 'slices';
import ComponentManager from 'strapi/components/ComponentManager';
import {
  StrapiAnnouncementBarData,
  StrapiHomepageData,
} from 'strapi/types/utils';
import { fetchAnnouncementBar, fetchMany, fetchOne } from 'strapi/utils/api';
import { isPageMarkedForDeindex } from 'strapi/utils/seo';
import {
  generatePrismicPagePaths,
  generateStrapiPagePaths,
} from 'util/generateCmsPagePaths';
import { i18nLocaleFormatter } from 'util/i18nLocaleFormatter';

import { AnnouncementBar } from '../../components/AnnoucementBar';
import { Homepage } from '../homepage';
import styles from './style.module.scss';

const getWebsiteLdScript = () => {
  return {
    __html: `
    {
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "Feather Insurance",
      "alternateName": "Feather",
      "url" : "${website.base}",
      "publisher": { "@type": "Organization", "@id" : "${website.base}#organization"}
    }`,
  };
};

declare global {
  interface Window {
    snowplow: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    Trustpilot: unknown;
    opera: string;
    Intercom?: Intercom_.IntercomCommand;
  }
}

export type BlogPostType = {
  node: {
    id: number;
    link: string;
    title: string;
    excerpt: string;
    featuredImage?: {
      node: {
        sourceUrl: string;
        altText: string;
      };
    };
  };
};

const PrismicPage = ({ page }: { page: PrismicDocument }) => {
  const {
    seo_title: seoTitle,
    seo_description: seoDescription,
    og_description: ogDescription,
    og_image: ogImage,
    slices,
  } = page.data;

  if (
    !seoTitle ||
    !seoDescription ||
    !ogDescription ||
    Object.keys(ogImage).length === 0
  ) {
    return (
      <div className={`pt64 ${styles.container}`}>
        <MissingRequiredFields />;
      </div>
    );
  }

  return (
    <>
      <NextSeo
        title={seoTitle}
        description={seoDescription}
        openGraph={{
          description: ogDescription,
          images: [
            {
              url: ogImage.url,
              width: ogImage.width,
              height: ogImage.height,
              alt: ogImage.alt,
            },
          ],
        }}
      />
      <SliceZone slices={slices} components={components as never} />
    </>
  );
};

const StrapiPage = ({ page }: { page: StrapiHomepageData }) => {
  const { seo, tags } = page;

  if (!seo) {
    throw new Error('Missing homepage metadata');
  }

  const { metaTitle, metaDescription, metaSocial, canonicalURL } = seo;
  const isMarkedForDeindex = isPageMarkedForDeindex(tags?.tags_list);

  if (!metaSocial || metaSocial.length === 0) {
    throw Error('Missing social media homepage preview data');
  }

  const [openGraphItem] = metaSocial;

  return (
    <>
      <NextSeo
        title={metaTitle}
        description={metaDescription}
        openGraph={{
          title: openGraphItem.title,
          description: openGraphItem.description,
          images: openGraphItem.image?.data
            ? [
                {
                  url: openGraphItem.image.data.attributes.url,
                  width: openGraphItem.image.data.attributes.width,
                  height: openGraphItem.image.data.attributes.height,
                  alt: openGraphItem.image.data.attributes.alternativeText,
                },
              ]
            : [],
        }}
        noindex={isMarkedForDeindex}
        canonical={canonicalURL}
      />
      <ComponentManager blocks={page.items} />
    </>
  );
};

const Home = ({
  blogPosts,
  navOffset,
  homepageStrapi,
  homepagePrismic,
  announcementBar,
}: {
  blogPosts: BlogPostType[];
  navOffset?: number;
  homepageStrapi: StrapiHomepageData | null;
  homepagePrismic: PrismicDocument | null;
  announcementBar: StrapiAnnouncementBarData | null;
}) => {
  if (homepageStrapi || homepagePrismic) {
    return (
      <>
        <Head>
          <script
            type="application/ld+json"
            id="WebsiteLd"
            dangerouslySetInnerHTML={getWebsiteLdScript()} // eslint-disable-line react/no-danger
          />
        </Head>
        <div className="pt64">
          {announcementBar && <AnnouncementBar {...announcementBar} />}
          {homepageStrapi ? (
            <StrapiPage page={homepageStrapi} />
          ) : (
            <PrismicPage page={homepagePrismic as PrismicDocument} />
          )}
        </div>
      </>
    );
  }

  /* If the current locale doesn't have a CMS-based homepage we render the hardcoded version */
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          id="WebsiteLd"
          dangerouslySetInnerHTML={getWebsiteLdScript()} // eslint-disable-line react/no-danger
        />
      </Head>
      <NextSeo
        title="Health & Liability Insurance for Expats in Germany"
        description="Feather gives insurance advice for expats in Germany. Our digital insurance agent will help you get the right insurance policies for your needs."
      />
      <Homepage
        announcementBar={announcementBar}
        blogPosts={blogPosts}
        navOffset={navOffset}
      />
    </>
  );
};

export async function getStaticProps({
  locale,
  previewData,
  preview,
}: GetStaticPropsContext) {
  try {
    if (!locale) throw Error('Locale was not set during the build time');

    const i18nProps = await serverSideTranslations(
      i18nLocaleFormatter(locale) || FORMATTED_DEFAULT_LOCALE,
      ['common', 'home']
    );

    const announcementBar = await fetchAnnouncementBar(
      'homepage',
      locale,
      preview
    );

    if (
      locale === ENGLISH_LOCALE_IDENTIFIER ||
      locale === GERMAN_LOCALE_IDENTIFIER
    ) {
      const blogPosts = await getLatestPostsByCategory();
      return {
        props: {
          blogPosts,
          homepageStrapi: null,
          homepagePrismic: null,
          announcementBar,
          ...i18nProps,
        },
      };
    }

    let homepagePrismic: PrismicDocument | null = null;
    const homepageStrapi = await fetchOne('homepages', {
      uid: 'homepage',
      locale,
      preview,
    });
    if (!homepageStrapi) {
      const client = createPrismicClient({ previewData });
      homepagePrismic = await client.getSingle('homepage', {
        lang: locale,
      });
    }

    return {
      props: {
        blogPosts: [],
        homepageStrapi,
        homepagePrismic,
        announcementBar,
        ...i18nProps,
      },
    };
  } catch (e) {
    const message = 'Failed to build homepage during static generation';
    captureException(e, {
      extra: {
        locale,
        description: message,
      },
    });
    throw new Error(message);
  }
}

export const getStaticPaths = async ({ locales }: GetStaticPathsContext) => {
  if (!locales) throw Error('Locales undefined during the build time');

  try {
    const prismicPages = (
      await Promise.all(
        PRISMIC_LOCALES.map((locale) =>
          createPrismicClient().getAllByType('homepage', { lang: locale })
        ).flat()
      )
    ).flat();

    const strapiPages = (
      await Promise.all(
        locales.map((locale) => fetchMany('homepages', { locale })).flat()
      )
    ).flat();

    return {
      paths: [
        ...generatePrismicPagePaths(prismicPages, DEFAULT_SUBDOMAIN_IDENTIFIER),
        ...generateStrapiPagePaths(strapiPages, DEFAULT_SUBDOMAIN_IDENTIFIER),
        // [Note] en-de & de-de homepages are not on CMS, hence the manually created paths
        {
          params: { subdomain: DEFAULT_SUBDOMAIN_IDENTIFIER, uid: '/' },
          locale: ENGLISH_LOCALE_IDENTIFIER,
        },
        {
          params: { subdomain: DEFAULT_SUBDOMAIN_IDENTIFIER, uid: '/' },
          locale: GERMAN_LOCALE_IDENTIFIER,
        },
      ],
      fallback: false,
    };
  } catch (error) {
    const message = 'Failed to fetch CMS pages during static path generation';
    captureException(error, {
      extra: { description: message },
    });
    throw new Error(message);
  }
};

export default Home;
